<template>
  <b-row>
    <b-col cols="12" order="1" class="position-relative z-1">
      <div class="mb-lg-16 mb-8">
        <slot name="title" />
      </div>
      <div class="form-row pb-8 pb-lg-0">
        <div v-for="(slide, idx) in slides" :key="idx" class="col-4 mb-lg-4">
          <b-button
            variant="white"
            class="p-0 shadow-sm position-relative transition-fast h-100"
            block
            @click="makeActive(idx)"
            :class="active == idx ? 'active' : ''"
          >
            <div
              class="
                py-3
                px-4
                d-flex
                text-left
                justify-content-lg-start justify-content-center
                rounded-lg
                bg-white
                h-100
              "
            >
              <div
                class="
                  mr-md-4
                  transition-fast
                  flex-shrink-0
                  mt-lg-1
                  h-6
                  w-6
                  h-lg-8
                  w-lg-8
                "
                :class="active == idx ? 'text-orange' : 'text-gray-600'"
              >
                <font-awesome-icon
                  class="h-100 w-100"
                  v-if="slide.icon.type === 'fa'"
                  :icon="slide.icon.value"
                />
                <inline-svg
                  class="h-100 w-100"
                  v-else-if="slide.icon.type === 'svg'"
                  :src="slide.icon.value"
                />
                <img
                  class="h-100 w-100 object-contain"
                  v-else-if="slide.icon.type === 'img'"
                  :src="slide.icon.value"
                />
              </div>
              <div>
                <div
                  class="
                    font-weight-bold
                    text-gray-800 text-md-md text-lg-xl
                    leading-md-tight
                    d-none d-lg-block
                    leading-tight
                    mb-1
                  "
                >
                  <span>{{ slide.title }}</span>
                </div>
                <div
                  class="
                    text-gray-500 text-lg-lg text-xs
                    leading-md-tight
                    d-none d-lg-block
                  "
                >
                  <span>{{ slide.description }}</span>
                </div>
              </div>
            </div>
          </b-button>
        </div>
      </div>
    </b-col>
    <b-col cols="12" order="2">
      <div
        class="position-relative transition-fast z-0"
        :style="`height: ${wrapperHeight}px`"
      >
        <transition name="fade" @enter="getHeight">
          <div :key="active" class="position-relative" ref="wrapper">
            <slot :active="active" />
          </div>
        </transition>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
  name: "SideCarousel",
  props: {
    slides: { type: Array, required: true }
  },
  components: {
    InlineSvg
  },
  data() {
    return {
      active: 0,
      wrapperHeight: 0
    };
  },
  methods: {
    getHeight(el) {
      setTimeout(() => {
        this.wrapperHeight = el.clientHeight;
      }, 0);
    },
    makeActive(idx) {
      this.active = idx;
    }
  },
  mounted() {
    this.getHeight(this.$refs.wrapper);
  }
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 300ms ease-in-out;
}

.active {
  @media (max-width: 992px) {
    // top: -0.375rem !important;
    border: 1px solid #f26522 !important;
    box-shadow: transparentize(#f26522, 0.5) 0px 0 48px 0px !important;
  }
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
